import React from "react";

export default function Page404() {
  return (
    <>
      <div>
        <h3>Error 404</h3>
        <h3>Side Ikke Funnet</h3>
        <p>Vi beklager, men siden du leter etter eksisterer ikke.</p>
      </div>
    </>
  );
}
