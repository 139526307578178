import React, { useEffect } from "react";
import $ from "jquery";
import "../../style/messageBox.css";

export default function MessageBox(props) {
  let messageBoxTitle = document.querySelector(".messageBoxTitle");
  let messageBoxText = document.querySelector(".messageBoxText");

  useEffect(() => {
    if (props.type === "successMakingRevision") {
      ramoveMessageClasses();
      messageBoxTitle.innerText = "Revisjon opprettet";
      messageBoxText.innerText =
        "Åpne dokumentet på vanlig vis, og benytt Ktext-tillegget for å levere inn endringene dine. Lykke til med skrivingen :)";

      $(".messageBox").addClass("success").fadeIn(500).delay(4000).fadeOut(1000);
    } else if (props.type === "successMakingRevision(Fast Revision)") {
      ramoveMessageClasses();
      messageBoxTitle.innerText = "Revisjon opprettet";
      messageBoxText.innerText = "Revisjon opprettet og sendt til redaktører.";

      $(".messageBox").addClass("success").fadeIn(500).delay(4000).fadeOut(1000);
    } else if (props.type === "generalFailMessage") {
      ramoveMessageClasses();
      messageBoxTitle.innerText = "Failed";
      messageBoxText.innerText = "Noe gikk galt :(";

      $(".messageBox").addClass("error").fadeIn(500).delay(4000).fadeOut(1000);
    } else if (props.type === "newVersion") {
      ramoveMessageClasses();
      messageBoxTitle.innerText = "Utdatert version";
      messageBoxText.innerHTML = "Det har kommet en ny versjon av Forfatterportalen. <br/> Vennligst last inn siden på nytt.";

      $(".messageBox").addClass("info").show();
    } else if (props.type === "errorGettingUserInfo") {
      ramoveMessageClasses();
      messageBoxTitle.innerText = "Error fetching user information";
      messageBoxText.innerText = "Sorry! We couldnot fetch your user account information correctly.";

      $(".messageBox").addClass("error").fadeIn(500).delay(10000).fadeOut(2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.type]);

  const ramoveMessageClasses = () => {
    $(".messageBox").hide().removeClass("info").removeClass("success").removeClass("error");
  };

  const closeMessageBox = () => {
    $(".messageBox").hide();
  };

  return (
    <div className="messageBox">
      <h1 className="messageBoxTitle">Title</h1>
      <button type="button" className="btn-close messageBoxCloseBtn" onClick={closeMessageBox}></button>
      <p className="messageBoxText">Message</p>
    </div>
  );
}
