import React, { useContext, useEffect, useState } from "react";

import Link from "@mui/material/Link";

import Loading from "./Serving/Loading";
import * as ApiCalls from "./Serving/ApiCalls";
import { azureTokenContext, userIdContext, userRolesContext } from "../App";

import * as Common from "./Serving/Common";

import "../style/tasks.css";

import { DataGridPremium } from "@mui/x-data-grid-premium";

import { useLocation } from "react-router-dom";

export default function ProofReaderTasks() {
  const azureToken = useContext(azureTokenContext);
  const userId = useContext(userIdContext);
  const userRoles = useContext(userRolesContext);

  const location = useLocation();

  const [loadingStatus, setLoadingStatus] = useState("loading");
  const [legalTasks, setLegalTasks] = useState({});
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = React.useState(10);

  useEffect(() => {
    if (userRoles.includes("KProofReader") && userId !== "") {
      ApiCalls.getTasksByRole(userId, "proofreader", azureToken)
        .then((response) => {
          setRowsFunc(response);
        })
        .catch((err) => {
          setLoadingStatus("error");
          console.error("get proofreader Tasks fail", err);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, location]);

  const linkTemplate = (props) => {
    const task = props.row.task;
    if (task && !task.canNotOpen) {
      if (task.status !== "ReadyForPublish") {
        return (
          <span
            onClick={() => {
              log("INFO", "Open word document", "KPanel", task.workItemId, task.userRole);
              logMetric("Open word document", "KPanel", "", "", "Open word document", task.workItemId, task.userRole);
            }}
          >
            <Link href={task.onlineLink} target="_blank" rel="noreferrer">
              Åpne dokument
            </Link>
          </span>
        );
      } else if (task.status === "ReadyForPublish") {
        return "Karnov gjør en siste sjekk.";
      }
    }
    return " - ";
  };

  const setRowsFunc = (response) => {
    setLoadingStatus("completed");
    setLegalTasks(response.legalTasks);
    setRows(
      response.legalTasks.map(function (task, i) {
        return {
          id: i,
          task: task,
          legalFieldName: task.legalFieldName,
          legalDocumentName: task.legalDocumentName,
          onlineLink: task.onlineLink,
          authorName: task.authorName,
          authorEmail: task.authorEmail,
          deadline: task.deadline,
          status: task.status,
          syncId: task.syncId,
          editorDeadline: task.editorDeadline,
          proofReaderDeadline: task.proofReaderDeadline,
          euDeadline: task.euDeadline,
        };
      })
    );
  };

  const columns = [
    // {
    //   field: "legalFieldName",
    //   headerName: "Rettsområde",
    //   width: 120,
    //   headerClassName: "gridHeader",
    //   // hide: true,
    // },
    {
      field: "legalDocumentName",
      headerName: "Navn på lov",
      width: 420,
      headerClassName: "gridHeader",
      renderCell: Common.documentNameTemplate,
    },
    {
      field: "onlineLink",
      headerName: "Redigere",
      width: 150,
      headerClassName: "gridHeader",
      renderCell: linkTemplate,
    },
    {
      field: "authorName",
      headerName: "Forfatter",
      width: 130,
      headerClassName: "gridHeader",
      renderCell: Common.authorNameTemplate,
    },
    {
      field: "deadline",
      headerName: "Innleveringsfrist",
      width: 120,
      headerClassName: "gridHeader",
      renderCell: Common.deadlineTemplate,
    },
    {
      field: "status",
      headerName: "Status",
      width: 130,
      headerClassName: "gridHeader",
      renderCell: Common.statusTemplate,
    },
    {
      field: "editorDeadline",
      headerName: "Fagredaktør Frist",
      width: 150,
      headerClassName: "gridHeader",
      renderCell: Common.contentEditorDeadlineTemplate,
    },
    {
      field: "proofReaderDeadline",
      headerName: "Korrektur Frist",
      width: 150,
      headerClassName: "gridHeader",
      renderCell: Common.proofReaderDeadlineTemplate,
    },
    {
      field: "euDeadline",
      headerName: "EU/EØS Frist",
      width: 150,
      headerClassName: "gridHeader",
      renderCell: Common.euEditorDeadlineTemplate,
    },
    {
      field: "syncId",
      headerName: "SyncID",
      width: 120,
      headerClassName: "gridHeader",
      // renderCell: euEditorDeadlineTemplate,
    },
  ];

  const log = (level, message, method, workItemId, userRole) => {
    ApiCalls.log(level, message, method, workItemId, userRole, userId, azureToken);
  };

  const logMetric = (category, subCategory, status, tag, message, workItemId, userRole) => {
    ApiCalls.logMetric(category, subCategory, status, tag, message, workItemId, userRole, userId, azureToken);
  };

  return (
    <>
      {!userRoles.includes("KProofReader") && <p>Du har ikke tilgang til denne siden!</p>}
      {userRoles.includes("KProofReader") && loadingStatus === "loading" && <Loading />}
      {userRoles.includes("KProofReader") && loadingStatus === "error" && <p>feil med å hente informasjon</p>}
      {userRoles.includes("KProofReader") && loadingStatus === "completed" && (
        <>
          <div className="taskResult">
            <DataGridPremium
              rows={rows}
              columns={columns}
              getRowHeight={() => "auto"}
              getRowClassName={() => "gridRow"}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={Common.setRowsPerPageOptionsArray(legalTasks)}
              pagination={true}
              components={{
                NoRowsOverlay: Common.NoRowDisplay,
                Toolbar: Common.QuickSearchToolbar,
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: "legalFieldName", sort: "desc" }],
                },
              }}
              sx={{
                p: 2,
                boxShadow: 0.5,
                border: 0.5,
                fontSize: "0.75rem",
                borderColor: "dark",
              }}
            />
          </div>
        </>
      )}
    </>
  );
}
