import React, { useContext, useEffect, useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { azureTokenContext } from "../App";

import { Routes, Route } from "react-router-dom";

import "../style/topNav.css";

export default function TopNav() {
  const azureToken = useContext(azureTokenContext);
  const isAuthenticated = useIsAuthenticated();
  const [userName, setUserName] = useState("");
  const { instance } = useMsal();

  useEffect(() => {
    if (azureToken && azureToken.account) {
      setUserName(azureToken.account.name);
    }
  }, [azureToken, isAuthenticated]);

  const handleLogout = (loginType) => {
    if (loginType === "redirect") {
      instance.logoutRedirect().catch((e) => {
        console.log("error", e);
      });
    }
  };

  const Headline = (text) => {
    return <h3 style={{ marginLeft: "2rem" }}>{text.text}</h3>;
  };

  return (
    <>
      <Navbar className="topNavbar">
        {/* add headline to top navbar */}
        <Routes>
          <Route path="/">
            <Route path="tasks/*" element={<Headline text="Oppgaver" />} />
            <Route path="authors-tasks" element={<Headline text="Oppgaver som forfatter" />} />
            <Route path="contentreviewers-tasks" element={<Headline text="Oppgaver som fagredaktør" />} />
            <Route path="proofreaders-tasks" element={<Headline text="Oppgaver som korrekturleser" />} />
            <Route path="tasks" element={<Headline text="Oppgaver" />} />
            <Route path="statistics" element={<Headline text="Statistikk" />} />
            <Route path="support" element={<Headline text="Support" />} />
          </Route>
        </Routes>

        <div className="topNavBox">
          <p className="topNavText">Velkommen - {userName} | </p>
          <button className="signOutButton" onClick={() => handleLogout("redirect")}>
            Logg ut
          </button>
        </div>
      </Navbar>
    </>
  );
}
