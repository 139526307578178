import React, { useState, useContext, useEffect } from "react";

import RevisionTree from "./RevisionTree";

import * as ApiCalls from "./ApiCalls";

import { azureTokenContext, userIdContext, messageBoxContext, userRolesContext } from "../../App";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

import "../../style/RevisionModal.css";

export default function RevisionModal(props) {
  // Fast revision : its a simplified proccess where author approves the notes without any changes so the status set to ready for review. fast revision is also by defalut a main revision
  // Main revision: The revision is about all of the document.
  // Part revision : The author choose a part of the document to make a workitem.

  const [checkedIds, setCheckedIds] = useState([]);
  const [isPendingMakeRevision, setIsPendingMakeRevision] = useState(false);
  const [isPartRevisionExpanded, setIsPartRevisionExpanded] = useState(false);

  let setMessageBoxProp = useContext(messageBoxContext);
  const azureToken = useContext(azureTokenContext);
  const userId = useContext(userIdContext);
  const userRoles = useContext(userRolesContext);

  useEffect(() => {
    if (props.openMakeRevision === true) {
      // Modal just opened
      setCheckedIds([]);
      setIsPendingMakeRevision(false);
      setIsPartRevisionExpanded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.openMakeRevision]);

  const handleCloseModal = () => props.setOpenMakeRevision(false);

  const setMessageBoxPropFunc = (msg) => {
    // since it is invocated by changes in prop, I want to make sure that it changes
    setMessageBoxProp("");
    setTimeout(() => {
      setMessageBoxProp(msg);
    }, 10);
  };

  const log = (level, message, method, workItemId, userRole) => {
    ApiCalls.log(level, message, method, workItemId, userRole, userId, azureToken);
  };

  const logMetric = (category, subCategory, status, tag, message, workItemId, userRole) => {
    ApiCalls.logMetric(category, subCategory, status, tag, message, workItemId, userRole, userId, azureToken);
  };

  const setRevisionFailedLog = (task) => {
    log("ERROR", "Revision not set", "Kpanel", task.workItemId, task.userRole);
    logMetric("Failed revision set", "", "Failed", "", "Faild set revision from KPanel", task.workItemId, task.userRole);
  };

  const getRevisionTree = () => {
    return <RevisionTree task={props.selectedTask} checkedIds={checkedIds} setCheckedIds={setCheckedIds} />;
  };

  const sentRevisionButtonClicked = (isMainRevision, isFastRevision) => {
    setIsPendingMakeRevision(true);

    // Fast revision : Its a simplified proccess where author approves the notes without any changes so the status set to ready for review. fast revision is also by defalut a main revision
    // Main revision: The revision is about all of the document.
    // Part revision : The Karnov employee choose a part of the document to make a workitem.

    const data = {
      source: props.selectedTask.legalSource,
      documentId: props.selectedTask.legalDocumentId,
      isFastRevision: isFastRevision,
      isMainRevision: isMainRevision,
      subIds: isMainRevision ? [] : checkedIds,
      userId: userId,
    };

    ApiCalls.makeRevision(data, azureToken)
      .then((response) => {
        if (response.success === true) {
          props.setUpdateResult(!props.updateResult);
          isFastRevision
            ? setMessageBoxPropFunc("successMakingRevision(Fast Revision)")
            : setMessageBoxPropFunc("successMakingRevision");
          log("INFO", "Revision set", "Kpanel", props.selectedTask.workItemId, props.selectedTask.userRole);
          logMetric(
            "Revision set",
            "",
            isFastRevision ? "ReadyForReview" : "Init",
            "",
            "Set revision from KPanel",
            props.selectedTask.workItemId,
            props.selectedTask.userRole
          );
        } else {
          setMessageBoxPropFunc("generalFailMessage");
          setRevisionFailedLog(props.selectedTask);
        }
      })
      .catch(() => {
        setMessageBoxPropFunc("generalFailMessage");
        setRevisionFailedLog(props.selectedTask);
      })
      .finally(() => {
        handleCloseModal();
        setIsPendingMakeRevision(false);
      });
  };

  const buttonThemeMUI = createTheme({
    palette: {
      ochre: {
        main: "#212121",
        light: "#E9DB5D",
        dark: "#A29415",
        contrastText: "#242105",
      },
    },
  });

  return (
    <>
      <ThemeProvider theme={buttonThemeMUI}>
        <Modal
          open={props.openMakeRevision}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "75vw",
              maxHeight: "85vh",
              overflow: "auto",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <h5>Oppdatering av noter</h5>
            <p className="modalDescription">
              Det er viktig for våre brukere at Karnov-kommentarene er oppdaterte. Vi ber deg derfor om å gjennomgå kommentarene
              dine minst én gang i året og oppdatere der det er nødvendig.
            </p>
            <h6 style={{ marginTop: "30px" }}>Notene mine må endres før republisering</h6>
            <p className="modalDescription">
              Klikk «Opprett» for å få et oppdateringsmanus med alle notene dine. Endringer føres inn med rød skriftfarge (spor
              endringer skal ikke benyttes). Lever oppdateringsmanuset ved å klikke «Sett til vurdering» i Ktext når du er ferdig.
              For hjelp med å komme i gang sjekk{" "}
              <a href="https://support.karnovgroup.no/komme-i-gang/" rel="noreferrer" target="_blank">
                denne guiden
              </a>
              . Alternativt send e-post til{" "}
              <a href="mailto:support@karnovgroup.no" rel="noreferrer" target="_blank">
                support@karnovgroup.no
              </a>
              . Forlaget tar en siste gjennomgang og republiserer alle noter med ny datostempling i Lovdata. Siden alle notene får
              ny datostempling og blir bekreftet à jour, er det viktig å påse at innholdet i alle noter er oppdatert.
            </p>

            <LoadingButton
              variant="contained"
              onClick={() => sentRevisionButtonClicked(true, false)}
              loading={isPendingMakeRevision}
              // loadingIndicator="Opprett…"
              // disabled={!isMainRevision && checkedIds.length === 0}
              title={"Opprett"}
              style={{
                fontSize: "15px",
                fontWeight: "300",
                marginLeft: "8px",
                padding: "6px 13px",
                textTransform: "none",
                borderRadius: "7px",
                pointerEvents: "auto",
              }}
            >
              Opprett
            </LoadingButton>

            <h6 style={{ marginTop: "30px" }}>Ingen endring</h6>
            <p className="modalDescription">
              Velg dette alternativet dersom alle dine kommentarnoter kan republiseres med ny datostempling uten endringer.
            </p>
            <div>
              <LoadingButton
                variant="contained"
                onClick={() => sentRevisionButtonClicked(true, true)}
                loading={isPendingMakeRevision}
                // loadingIndicator="Opprett…"
                // disabled={!isMainRevision}
                title={"Ingen endring"}
                style={{
                  fontSize: "15px",
                  fontWeight: "300",
                  marginLeft: "8px",
                  padding: "6px 13px",
                  textTransform: "none",
                  borderRadius: "7px",
                  pointerEvents: "auto",
                }}
              >
                Ingen endring
              </LoadingButton>
            </div>

            {userRoles.includes("KEmployee") && (
              <>
                <div style={{ marginTop: "30px" }}>
                  <button className="accordionButton" onClick={() => setIsPartRevisionExpanded(!isPartRevisionExpanded)}>
                    {isPartRevisionExpanded && <CloseIcon />}
                    {!isPartRevisionExpanded && <AddIcon />}
                  </button>
                  <h6 style={{ display: "inline" }}>Velg noter som skal oppdateres (bare Karnov-ansatte kan se dette)</h6>
                </div>
                {isPartRevisionExpanded && (
                  <div className="partRevisionContainer">
                    <div>{getRevisionTree()}</div>
                    <div>
                      <LoadingButton
                        variant="contained"
                        onClick={() => sentRevisionButtonClicked(false, false)}
                        loading={isPendingMakeRevision}
                        // loadingIndicator="Opprett…"
                        disabled={checkedIds.length === 0}
                        title={"Opprett"}
                        style={{
                          fontSize: "15px",
                          fontWeight: "300",
                          marginTop: "10px",
                          marginLeft: "8px",
                          padding: "6px 13px",
                          textTransform: "none",
                          borderRadius: "7px",
                          pointerEvents: "auto",
                        }}
                      >
                        Opprett
                      </LoadingButton>
                    </div>
                  </div>
                )}
              </>
            )}

            <div className="sideBySideContainer">
              <div>
                <p className="modalDescription">
                  Ta kontakt med{" "}
                  <a href="mailto:support@karnovgroup.no" rel="noreferrer" target="_blank">
                    support@karnovgroup.no
                  </a>{" "}
                  dersom du kun ønsker å oppdatere enkeltnoter. Angi i emnefeltet at det gjelder en enkeltoppdatering utenom den
                  årlige oppdateringen av alle notene.
                </p>
              </div>
              <div>
                <Button
                  variant="outlined"
                  color="ochre"
                  onClick={handleCloseModal}
                  style={{
                    fontSize: "15px",
                    fontWeight: "600",
                    marginLeft: "15px",
                    padding: "4px 10px",
                    textTransform: "none",
                    borderRadius: "7px",
                    pointerEvents: "auto",
                    borderWidth: "1px",
                    borderColor: "black",
                  }}
                >
                  Lukk vinduet
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
      </ThemeProvider>
    </>
  );
}
