import React from "react";

// import videoFile from "../../assets/video/Revisjon.mp4";

import "../style/home.css";

export default function Home() {
  return (
    <>
      <div>
        <div>
          <h6 style={{ display: "inline" }}>Rediger din profilside: </h6>
          <a href="https://tilgang.karnov.no" target="_blank" style={{ display: "inline" }} rel="noreferrer">
            https://tilgang.karnov.no
          </a>
        </div>
        <br></br>
        <hr></hr>
        <br></br>
        <h3>Nyheter!</h3>
        <h4>Oppdatering av KWord</h4>
        <p>August 2023 oppdaterte vi KWord. Denne oppdateringen inkluderer følgende endringer:</p>
        <ol>
          <li>
            <b>Navnendring til Ktext.</b>
          </li>
          <li>
            <b>Små feilrettinger:</b> På bakgrunn av tilbakemeldinger fra fagredaktører og forfattere har vi foretatt
            feilrettinger som vil gi en mer stabil brukeropplevelse.
          </li>
        </ol>
        <p>Vi setter pris på dine tilbakemeldinger, slik at vi kan fortsette å forbedre Ktext.</p>
        <p>
          Dersom du har installert KWord fra før skal oppdateringen skje automatisk (dessverre opplever vi at dette ikke gjelder
          for alle). Om dette ikke skjer kan du følge veiledningen i menyen til venstre for installasjon eller ta kontakt med
          support.
        </p>
        <br></br>
        <hr></hr>
        <br></br>
        <h4>Revisjoner</h4>
        <p>Nå kan du starte revisjoner av lovkommentarene dine selv.</p>
        {/* <p>Se video:</p>
        <video className="videoPlayer" controls>
          <source src="/revisjon.mp4" type="video/mp4" />
        </video>  */}
      </div>
    </>
  );
}
