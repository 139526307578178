import React, { useContext, useEffect, useState } from "react";
import Loading from "./Serving/Loading";
import * as ApiCalls from "./Serving/ApiCalls";

import { azureTokenContext, userIdContext, userRolesContext } from "../App";

import moment from "moment";
import "moment/locale/nb";

import { styled } from "@mui/material/styles";
import { DataGridPremium, GridToolbarQuickFilter } from "@mui/x-data-grid-premium";
import Box from "@mui/material/Box";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import "../style/statistikk.css";

export default function Statistics() {
  const azureToken = useContext(azureTokenContext);
  const userId = useContext(userIdContext);
  const userRoles = useContext(userRolesContext);

  const [loadingStatus, setLoadingStatus] = useState("loading");
  const [stats, setStats] = useState({});
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = React.useState(10);

  useEffect(() => {
    if (userRoles.includes("KContentReviewer") && userId !== "") {
      ApiCalls.contentReviewerStats(userId, azureToken)
        .then((response) => {
          setRowsFunc(response);
        })
        .catch((err) => {
          setLoadingStatus("error");
          console.error("get contentReviewer stats fail", err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const setRowsFunc = (response) => {
    setLoadingStatus("completed");
    setStats(response.result);
    setRows(
      response.result.map(function (stat, i) {
        return {
          id: i,
          stat: stat,
          source: stat.source,
          documentId: stat.documentId,
          documentTitle: stat.documentTitle,
          authorId: stat.authorId,
          authorName: stat.authorName,
          authorEmail: stat.authorEmail,
          numberOfSubIds: stat.numberOfSubIds,
          numberOfNotes: stat.numberOfNotes,
          workItemCount: stat.workItemCount,
          firstPublishedDate: stat.firstPublishedDate,
          lastPublishedDate: stat.lastPublishedDate,
          lastAuthorValidatedDate: stat.lastAuthorValidatedDate,
        };
      })
    );
  };

  const setRowsPerPageOptionsArray = () => {
    const length = stats.length;
    let result = [10];
    if (length > 10) {
      if (length <= 20) {
        result.push(length);
      } else {
        result.push(20);
        if (length <= 50) {
          result.push(length);
        } else {
          result.push(50);
          if (length <= 100) {
            result.push(length);
          } else {
            result.push(100);
            result.push(length);
          }
        }
      }
    }
    return result;
  };

  const NoRowDisplay = () => {
    return (
      <div>
        <p className="noRowDisplay">Ingen statistikk tilgjengelig!</p>
      </div>
    );
  };

  const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        fontSize: theme.typography.pxToRem(13),
        border: "1px solid #dadde9",
      },
    })
  );

  const authorNameTemplate = (props) => {
    const stat = props.row.stat;
    if (stat) {
      const mailtoLink = `mailto:${stat.authorEmail}`;
      return (
        <HtmlTooltip
          title={
            <React.Fragment>
              <a href={mailtoLink} target="_blank" rel="noreferrer">
                {stat.authorEmail}
              </a>
            </React.Fragment>
          }
        >
          <p style={{ margin: "auto 1px" }}>{stat.authorName}</p>
        </HtmlTooltip>
      );
    }
    return " - ";
  };

  const firstPublishedDateTemplate = (props) => {
    const stat = props.row.stat;
    if (stat && stat.firstPublishedDate) {
      return moment(stat.firstPublishedDate).format("ll");
    }
    return " - ";
  };

  const lastPublishedDateTemplate = (props) => {
    const stat = props.row.stat;

    if (stat && stat.lastPublishedDate) {
      return moment(stat.lastPublishedDate).format("ll");
    }
    return " - ";
  };

  const lastAuthorValidatedDateTemplate = (props) => {
    const stat = props.row.stat;
    if (stat && stat.lastAuthorValidatedDate) {
      const daysDiff = dateDifferenceInDays(stat.lastPublishedDate);
      const dateString = moment(stat.lastAuthorValidatedDate).format("ll");
      if (daysDiff < 330) {
        return dateString;
      } else {
        return <p className={`dateColorification ${daysDiff < 365 ? "yellowBackground" : "redBackground"}`}>{dateString}</p>;
      }
    } else {
      return " - ";
    }
  };

  const dateDifferenceInDays = (date) => {
    if (date) {
      const now = moment();
      const momentDate = moment(date);
      return now.diff(momentDate, "days");
    } else {
      return null;
    }
  };

  const renderNumbers = (props) => {
    const number = props.row.stat[props.field];
    return <span style={{ margin: "auto" }}>{number}</span>;
  };

  function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
        <GridToolbarQuickFilter />
      </Box>
    );
  }

  const columns = [
    {
      field: "documentTitle",
      headerName: "Navn på lov",
      width: 350,
      headerClassName: "gridHeader",
      // hide: true,
    },
    {
      field: "documentId",
      headerName: "Lovnummer",
      width: 110,
      headerClassName: "gridHeader",
      // hide: true,
    },

    {
      field: "authorName",
      headerName: "Forfatter",
      width: 150,
      headerClassName: "gridHeader",
      renderCell: authorNameTemplate,
    },
    {
      field: "firstPublishedDate",
      headerName: "Første publisering",
      width: 140,
      headerClassName: "gridHeader",
      renderCell: firstPublishedDateTemplate,
    },
    {
      field: "lastPublishedDate",
      headerName: "Siste publisering",
      width: 120,
      headerClassName: "gridHeader",
      renderCell: lastPublishedDateTemplate,
    },
    {
      field: "lastAuthorValidatedDate",
      headerName: "Siste hovedrevidert",
      width: 130,
      headerClassName: "gridHeader",
      renderCell: lastAuthorValidatedDateTemplate,
    },
    {
      field: "numberOfSubIds",
      headerName: "Antall paragrafer",
      width: 120,
      headerClassName: "gridHeader",
      renderCell: renderNumbers,
    },
    {
      field: "numberOfNotes",
      headerName: "Antall notater",
      width: 120,
      headerClassName: "gridHeader",
      renderCell: renderNumbers,
    },
    {
      field: "workItemCount",
      headerName: "Antall aktive oppgaver",
      width: 150,
      headerClassName: "gridHeader",
      renderCell: renderNumbers,
    },
  ];

  return (
    <>
      {!userRoles.includes("KContentReviewer") && <p>Du har ikke tilgang til denne siden!</p>}
      {userRoles.includes("KContentReviewer") && loadingStatus === "loading" && <Loading />}
      {userRoles.includes("KContentReviewer") && loadingStatus === "error" && <p>feil med å hente informasjon</p>}
      {userRoles.includes("KContentReviewer") && loadingStatus === "completed" && (
        <>
          <div className="contentReviewerResult">
            <DataGridPremium
              rows={rows}
              columns={columns}
              getRowHeight={() => "auto"}
              getRowClassName={() => "gridRow"}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={setRowsPerPageOptionsArray()}
              pagination={true}
              components={{
                NoRowsOverlay: NoRowDisplay,
                Toolbar: QuickSearchToolbar,
              }}
              // initialState={{
              //   sorting: {
              //     sortModel: [{ field: "legalFieldName", sort: "desc" }],
              //   },
              // }}
              sx={{
                p: 2,
                boxShadow: 0.5,
                border: 0.5,
                fontSize: "0.75rem",
                borderColor: "dark",
              }}
            />
          </div>
        </>
      )}
    </>
  );
}
