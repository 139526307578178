import moment from "moment";
import "moment/locale/nb";

import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@mui/lab/TreeItem";
import Box from "@mui/material/Box";
import { GridToolbarQuickFilter } from "@mui/x-data-grid-premium";

export function setRowsPerPageOptionsArray(legalTasks) {
  const length = legalTasks.length;
  let result = [10];
  if (length > 10) {
    if (length <= 20) {
      result.push(length);
    } else {
      result.push(20);
      if (length <= 50) {
        result.push(length);
      } else {
        result.push(50);
        if (length <= 100) {
          result.push(length);
        } else {
          result.push(100);
          result.push(length);
        }
      }
    }
  }
  return result;
}

export function statusTemplate(props) {
  const task = props.row.task;
  if (task) {
    if (task.status === "Init" && task.userRole === "ContentReviewer") return "Forfatter arbeider";
    if (task.status === "Init") return "Under arbeid";
    if (task.status === "ReadyForReview") return "Til gjennomgang";
    if (task.status === "ReadyForPublish") return "Klar for publisering";
    if (task.status === "Published") return "Levert";
    if (task.status === "Delivered") return "Publisert";
    if (task.status === "Deleted") return "Slettet";
    if (task.status === "UpgradeVersion") return "Versjonsoppgraderes (låst)";
    if (task.status === "UpgradedVersion") return "Ferdig versjonsoppgradering";
  }
  return " - ";
}

export function myRoleTemplate(props) {
  const task = props.row.task;
  if (task) {
    if (task.userRole === "Author") return "Forfatter";
    if (task.userRole === "SharedWith") return "Co-author";
    if (task.userRole === "ContentReviewer") return "Fagredaktør";
    if (task.userRole === "KarnovContentReviewer") return "Karnov fagredaktør";
    if (task.userRole === "KarnovContentEditor") return "Karnov redaktør";
    if (task.userRole === "ProofReader") return "Korrekturleser";

    return task.userRole;
  }
  return " - ";
}

export function findWorkItemIdFromPath() {
  let path = window.location.pathname;
  if (path.startsWith("/tasks/")) {
    return path.substring(7);
  } else {
    return false;
  }
}

export function NoRowDisplay() {
  if (findWorkItemIdFromPath()) {
    return (
      <div>
        <p className="noRowDisplay">Det er ikke et workitem med denne IDen!</p>
      </div>
    );
  } else {
    return (
      <div>
        <p className="noRowDisplay">Du har ingen oppgaver!</p>
      </div>
    );
  }
}

const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: theme.typography.pxToRem(13),
    border: "1px solid #dadde9",
  },
}));

export function authorNameTemplate(props) {
  const task = props.row.task;
  if (task) {
    const mailtoLink = `mailto:${task.authorEmail}`;
    return task.userRole === "Author" ? (
      ""
    ) : (
      <HtmlTooltip
        title={
          <>
            <a href={mailtoLink} target="_blank" rel="noreferrer">
              {task.authorEmail}
            </a>
          </>
        }
      >
        <p style={{ margin: "auto 1px" }}>{task.authorName}</p>
      </HtmlTooltip>
    );
  }
  return " - ";
}

export function checkDeadlineForRoles(deadline, approvedDate) {
  if (deadline) {
    if (approvedDate) {
      return "Godkjent " + moment(approvedDate).format("ll");
    } else {
      const today = moment();
      const deadlineDate = moment(deadline);
      const diff = today.diff(deadlineDate, "days");

      if (diff > 0) {
        //Deadline not met
        return <div className="passedDeadline">{deadlineDate.format("ll")}</div>;
      } else if (diff > -30) {
        //30 days to deadline
        return <div className="nearDeadline">{deadlineDate.format("ll")}</div>;
      } else {
        return deadlineDate.format("ll");
      }
    }
  }
}

export function documentNameTemplate(props) {
  const task = props.row.task;
  if (task) {
    return (
      <TreeView
        aria-label="file system navigator"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        className="mainTreeItem"
        sx={{
          overflowY: "auto",
          padding: "8px 2px",
        }}
      >
        <nobr>
          <TreeItem nodeId={"1"} id={task.legalDocumentId} label={task.legalDocumentName} className="mainTreeItem">
            {task.toCElements ? getTocElements(task.toCElements, 2) : ""}
          </TreeItem>
        </nobr>
      </TreeView>
    );
  }
}

export function getTocElements(toCElements, noteIdCounter) {
  return (
    <>
      {toCElements.map(function (toCElement, i) {
        return (
          <TreeItem
            key={i}
            nodeId={(noteIdCounter + i).toString()}
            id={toCElement.id}
            label={toCElement.name}
            className="subTreeItem"
          >
            {toCElement.toCElements ? getTocElements(toCElement.toCElements, (noteIdCounter + i) * 1000) : ""}
          </TreeItem>
        );
      })}
    </>
  );
}

export function deadlineTemplate(props) {
  const task = props.row.task;
  if (task && task.deadline) {
    const today = moment();
    const deadlineDate = moment(task.deadline);
    const diff = today.diff(deadlineDate, "days");

    if (diff > 0) {
      //Deadline not met
      return <div className="passedDeadline">{deadlineDate.format("ll")}</div>;
    } else if (diff > -30) {
      //30 days to deadline
      return <div className="nearDeadline">{deadlineDate.format("ll")}</div>;
    } else {
      return deadlineDate.format("ll");
    }
  }
  return " - ";
}

export function contentEditorDeadlineTemplate(props) {
  const task = props.row.task;
  if (task) {
    const deadline = task.editorDeadline;
    const approvedDate = task.editorApproved;
    return checkDeadlineForRoles(deadline, approvedDate);
  }
  return " - ";
}

export function proofReaderDeadlineTemplate(props) {
  const task = props.row.task;
  if (task) {
    const deadline = task.proofReaderDeadline;
    const approvedDate = task.proofReaderApproved;
    return checkDeadlineForRoles(deadline, approvedDate);
  }
  return " - ";
}

export function euEditorDeadlineTemplate(props) {
  const task = props.row.task;
  if (task) {
    const deadline = task.euDeadline;
    const approvedDate = task.euApproved;
    return checkDeadlineForRoles(deadline, approvedDate);
  }
  return " - ";
}

export function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <GridToolbarQuickFilter />
    </Box>
  );
}
