import React, { useContext, useEffect, useState } from "react";

import * as ApiCalls from "./Serving/ApiCalls";
import * as Common from "./Serving/Common";
import RevisionModal from "./Serving/RevisionModal";

import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import Loading from "./Serving/Loading";

import { azureTokenContext, userIdContext, userRolesContext } from "../App";

import "../style/tasks.css";

import { DataGridPremium } from "@mui/x-data-grid-premium";

import { useLocation } from "react-router-dom";

export default function Tasks() {
  const azureToken = useContext(azureTokenContext);
  const userId = useContext(userIdContext);
  const userRoles = useContext(userRolesContext);

  const location = useLocation();

  const [loadingStatus, setLoadingStatus] = useState("loading");
  const [legalTasks, setLegalTasks] = useState({});
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = React.useState(10);

  const [openMakeRevision, setOpenMakeRevision] = React.useState(false);
  const [selectedTask, setSelectedTask] = React.useState({});
  const [updateResult, setUpdateResult] = React.useState(false);

  useEffect(() => {
    if (userRoles.includes("KAuthor") && userId !== "") {
      const workItemId = Common.findWorkItemIdFromPath();
      ApiCalls.getTasks(userId, workItemId, azureToken)
        .then((response) => {
          setRowsFunc(response);
        })
        .catch((err) => {
          setLoadingStatus("error");
          console.error("get Tasks fail", err);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, updateResult, location]);

  const handleClickMakeRevision = (task) => {
    setSelectedTask((obj) => {
      return Object.assign(obj, task);
    });

    setOpenMakeRevision(true);
  };

  function linkTemplate(props) {
    const task = props.row.task;
    if (task && !task.canNotOpen) {
      if (task.isWorkItem === true) {
        if (task.status !== "ReadyForPublish") {
          return (
            <span
              onClick={() => {
                log("INFO", "Open word document", "KPanel", task.workItemId, task.userRole);
                logMetric("Open word document", "KPanel", "", "", "Open word document", task.workItemId, task.userRole);
              }}
            >
              <Link href={task.onlineLink} target="_blank" rel="noreferrer">
                Åpne dokument
              </Link>
            </span>
          );
        } else if (task.status === "ReadyForPublish") {
          return "Karnov gjør en siste sjekk.";
        }
      } else {
        return (
          <>
            {task.userRole === "Author" && (
              <Button
                variant="contained"
                onClick={() => handleClickMakeRevision(task)}
                style={{
                  fontSize: "0.75rem",
                  fontWeight: "none",
                  padding: "4px 10px",
                  textTransform: "none",
                }}
              >
                Oppdater
              </Button>
            )}
          </>
        );
      }
    }
    return " - ";
  }

  const setRowsFunc = (response) => {
    setLoadingStatus("completed");
    setLegalTasks(response.legalTasks);
    setRows(
      response.legalTasks.map(function (task, i) {
        return {
          id: i,
          task: task,
          legalFieldName: task.legalFieldName,
          legalDocumentName: task.legalDocumentName,
          onlineLink: task.onlineLink,
          userRole: task.userRole,
          authorName: task.authorName,
          authorEmail: task.authorEmail,
          deadline: task.deadline,
          status: task.status,
          syncId: task.syncId,
          editorDeadline: task.editorDeadline,
          proofReaderDeadline: task.proofReaderDeadline,
          euDeadline: task.euDeadline,
        };
      })
    );
  };

  const columns = [
    // {
    //   field: "legalFieldName",
    //   headerName: "Rettsområde",
    //   width: 120,
    //   headerClassName: "gridHeader",
    //   // hide: true,
    // },
    {
      field: "legalDocumentName",
      headerName: "Navn på lov",
      width: 420,
      headerClassName: "gridHeader",
      renderCell: Common.documentNameTemplate,
    },
    {
      field: "onlineLink",
      headerName: "Redigere",
      width: 150,
      headerClassName: "gridHeader",
      renderCell: linkTemplate,
    },
    {
      field: "userRole",
      headerName: "Min rolle",
      width: 100,
      headerClassName: "gridHeader",
      renderCell: Common.myRoleTemplate,
    },
    {
      field: "authorName",
      headerName: "Forfatter",
      width: 100,
      headerClassName: "gridHeader",
      renderCell: Common.authorNameTemplate,
    },
    {
      field: "deadline",
      headerName: "Innleveringsfrist",
      width: 120,
      headerClassName: "gridHeader",
      renderCell: Common.deadlineTemplate,
    },
    {
      field: "status",
      headerName: "Status",
      width: 130,
      headerClassName: "gridHeader",
      renderCell: Common.statusTemplate,
    },
    {
      field: "editorDeadline",
      headerName: "Fagredaktør Frist",
      width: 150,
      headerClassName: "gridHeader",
      renderCell: Common.contentEditorDeadlineTemplate,
    },
    {
      field: "proofReaderDeadline",
      headerName: "Korrektur Frist",
      width: 150,
      headerClassName: "gridHeader",
      renderCell: Common.proofReaderDeadlineTemplate,
    },
    {
      field: "euDeadline",
      headerName: "EU/EØS Frist",
      width: 150,
      headerClassName: "gridHeader",
      renderCell: Common.euEditorDeadlineTemplate,
    },
    {
      field: "syncId",
      headerName: "SyncID",
      width: 120,
      headerClassName: "gridHeader",
      // renderCell: euEditorDeadlineTemplate,
    },
  ];

  const log = (level, message, method, workItemId, userRole) => {
    ApiCalls.log(level, message, method, workItemId, userRole, userId, azureToken);
  };

  const logMetric = (category, subCategory, status, tag, message, workItemId, userRole) => {
    ApiCalls.logMetric(category, subCategory, status, tag, message, workItemId, userRole, userId, azureToken);
  };

  return (
    <>
      {!userRoles.includes("KAuthor") && <p>Du har ikke tilgang til denne siden!</p>}
      {userRoles.includes("KAuthor") && loadingStatus === "loading" && <Loading />}
      {userRoles.includes("KAuthor") && loadingStatus === "error" && <p>feil med å hente informasjon</p>}
      {userRoles.includes("KAuthor") && loadingStatus === "completed" && (
        <>
          <div className="taskResult">
            <DataGridPremium
              rows={rows}
              columns={columns}
              getRowHeight={() => "auto"}
              getRowClassName={() => "gridRow"}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={Common.setRowsPerPageOptionsArray(legalTasks)}
              pagination={true}
              components={{
                NoRowsOverlay: Common.NoRowDisplay,
                Toolbar: Common.QuickSearchToolbar,
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: "legalFieldName", sort: "desc" }],
                },
              }}
              sx={{
                p: 2,
                boxShadow: 0.5,
                border: 0.5,
                fontSize: "0.75rem",
                borderColor: "dark",
              }}
            />
          </div>
          <RevisionModal
            openMakeRevision={openMakeRevision}
            setOpenMakeRevision={setOpenMakeRevision}
            updateResult={updateResult}
            setUpdateResult={setUpdateResult}
            selectedTask={selectedTask}
          />
        </>
      )}
    </>
  );
}
