export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENTID,
    // clientSecret: "VXI8Q~l1vJnCSFoQmE68GiS3uHBz_fSJN1t3rcI.",
    authority: process.env.REACT_APP_TENANTID, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: process.env.REACT_APP_SITE_URL,
    postLogoutRedirectUri: "https://www.karnovgroup.no/",
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: [
    // "User.Read",
    // "api://20a65c2b-1eb4-4065-84df-e6a8739cda1b/access_as_user",
    // "api://4a9e418d-8bef-45d4-82c0-87ad7326add1/access_as_user",
    process.env.REACT_APP_LOGIN_SCOPES,
  ],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com",
};
