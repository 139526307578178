import React, { useState } from "react";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export default function RevisionTree(props) {
  let selectedTask = props.task;

  const initExpand = [selectedTask.legalDocumentId];
  const [expanded, setExpanded] = useState(initExpand);

  const Label = ({ children }) => <span style={{ fontSize: "14px", fontWeight: "4800" }}>{children}</span>;

  //make selectedTask like the node CheckboxTree wants
  let nodes = [{}];
  Object.assign(nodes[0], selectedTask);
  nodes[0].value = nodes[0].legalDocumentId;
  nodes[0].label = <Label>{nodes[0].legalDocumentName}</Label>;
  nodes[0].children = nodes[0].toCElements;
  const mapThroughTask = (children) => {
    children.forEach((child) => {
      child.value = child.id;
      child.label = <Label>{child.name}</Label>;
      if (child.toCElements) {
        child.children = child.toCElements;
        mapThroughTask(child.toCElements);
      }
    });
  };

  if (nodes[0].children) {
    mapThroughTask(nodes[0].children);
  }

  return (
    <>
      {/* {!props.isMainRevision && ( */}
      <CheckboxTree
        nodes={nodes}
        checked={props.checkedIds}
        expanded={expanded}
        onCheck={(checkedIds) => {
          props.setCheckedIds(checkedIds);
        }}
        onExpand={(expanded) => setExpanded(expanded)}
        checkModel="leaf"
        icons={{
          check: <CheckBoxIcon color="primary" />,
          uncheck: <CheckBoxOutlineBlankIcon color="primary" />,
          halfCheck: <IndeterminateCheckBoxIcon color="primary" />,
          expandClose: <ChevronRightIcon />,
          expandOpen: <ExpandMoreIcon />,
          expandAll: "",
          collapseAll: "",
          parentClose: "",
          parentOpen: "",
          leaf: "",
        }}
      />
      {/* )} */}
      {/* {props.isMainRevision && (
        <CheckboxTree
          nodes={nodes}
          checked={props.checkedIds}
          expanded={expanded}
          onCheck={(checkedIds) => {
            props.setCheckedIds(checkedIds);
          }}
          onExpand={(expanded) => setExpanded(expanded)}
          checkModel="leaf"
          icons={{
            check: "",
            uncheck: "",
            halfCheck: "",
            expandClose: <ChevronRightIcon />,
            expandOpen: <ExpandMoreIcon />,
            expandAll: "",
            collapseAll: "",
            parentClose: "",
            parentOpen: "",
            leaf: "",
          }}
        />
      )} */}
    </>
  );
}
