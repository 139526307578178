import React from "react";

export default function Support() {
  return (
    <div>
      <ul>
        <li>
          <a
            href="https://newcolaw.sharepoint.com/:v:/r/sites/NewcoLawAS/Delte%20dokumenter/Forfatterportalen/Introduksjonskurs_(HQ).mp4"
            target="_blank"
            rel="noreferrer"
          >
            Introduksjonskurs
          </a>
        </li>
        <li>
          <div>
            <a
              href="https://newcolaw.sharepoint.com/:b:/r/sites/NewcoLawAS/Delte%20dokumenter/Forfatterportalen/Forfatterveiledning.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Forfatterveiledning
            </a>
          </div>
        </li>
        <li>
          <div>
            <a
              href="https://newcolaw.sharepoint.com/:b:/r/sites/NewcoLawAS/Delte%20dokumenter/Forfatterportalen/hurtigguide.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Hurtigguide til forfatterportalen
            </a>
          </div>
        </li>
        <li>
          <div>
            <a
              href="https://newcolaw.sharepoint.com/:b:/r/sites/NewcoLawAS/Delte%20dokumenter/Forfatterportalen/Veiledning%20til%20forfatterportalen.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Veiledning forfatterportalen
            </a>
          </div>
        </li>
        <li>
          <a href="https://support.karnov.no" target="_blank" rel="noreferrer">
            Kunnskapsbase
          </a>
        </li>
      </ul>
      <div>
        <b>
          Andre spørsmål? Kontakt oss på <a href="mailto:support@karnovgroup.no">support@karnovgroup.no</a>
        </b>
      </div>
    </div>
  );
}
